@import "variables";

body {
  background-color: $background;
  font-family: "Helvetica", "Roboto", sans-serif;
  color: $text;
  overflow-y: scroll;
  letter-spacing: 0.001em;
}

a {
  text-decoration: none;
  color: $text;
}
a.underlined:hover {
  text-decoration: underline;
}

p {
  margin-top: 8px;
  margin-bottom: 0;
}

.muted {
  opacity: $muted;
}

.post-content p:first-child {
  margin-top: 3px;
}
